<template>
  <div
    :id="'apollo_3d_graph' + widgetId"
    :style="{
      width: this.widgetWidth,
      height: this.widgetHeight,
      zIndex: 9999,
    }"
    @contextmenu="this.setContextIdWidget"
    class="shadow-lg"
  >
    <span class="hidden">{{ darkDark }}</span>
    <!-- Plotly chart will be drawn inside this DIV -->
  </div>
</template>

<script>
import { getLogname } from "../../../js/common.js";
import apiService from "../../../api/services";
import { newPlot } from "plotly.js-dist-min";

export default {
  name: "3dGraphWidgetTrajectory",
  components: {},

  data() {
    return {
      my3d_plot: "",
      unitData: {
        azi: "",
        brate: "(dega/100ft)",
        buttosensor: "(ft)",
        closazi: "",
        closdist: "(ft)",
        dispew: "(ft)",
        dispns: "(ft)",
        dogleg: "(dega/100ft)",
        gtf: "",
        holesection: "",
        incl: "",
        md: "(ft)",
        mtf: "",
        trate: "(dega/100ft)",
        tvd: "(ft)",
        vsection: "(ft)",
      },
      gridcolor: "",
      selectedTags: {
        y: "dispew",
        x: "dispns",
        z: "tvd",
        a: "md",
        b: "incl",
        c: "azi",
        d: "vertsect",
        e: "dogleg",
      },
      idx: -1,
      widgetWaitTimer: true,
      wellId: "",
      wellboreId: "",
      logId: "",
      wellboreState: "",
      logType: "",
      traces: {},
      tracesText: {},
      // mnemonicTags: ["lstk", "spm1", "tva", "drtm"],
      mnemonicTags: [
        this.mnemonicTag1,
        this.mnemonicTag2,
        this.mnemonicTag3,
        this.mnemonicTag4,
      ],
      table: "",
      megaData: [
        {
          y: 0,
          x: 0,
          z: 0,
        },
      ],
      megaDataPt: [
        {
          y: 0,
          x: 0,
          z: 0,
        },
      ],
      graphData: {
        rows: [],
        rowsPlan: [],
        columns: [],
      },
      replayTimeIntvPlanVsActual:null
    };
  },
  props: {
    displayId: String,
    widgetiD: String,
    widgetHeight: {
      type: [String, Number],
      default: "200",
    },
    widgetWidth: {
      type: [String, Number],
      default: "350",
    },
    gridColor: {
      type: String,
      default: "white",
    },
    backgroundColor: {
      type: String,
      default: "rgb(200, 200, 230)",
    },
    graphMnemonics: {
      type: Array,
      default: ["lstk", "spm1", "tva", "drtm"],
    },
    verticalcardBG: {
      default: "var(--navBar2Bg)",
    },
    lightThemeDarkColor: {
      default: "black",
    },
    mnemonicTag1: String,
    mnemonicTag2: String,
    mnemonicTag3: String,
    mnemonicTag4: String,
    widgetId: String,
    xAutoScale: {
      type: [Boolean],
      default: false,
    },
    yAutoScale: {
      type: [Boolean],
      default: false,
    },
    zAutoScale: {
      type: [Boolean],
      default: false,
    },
    xRangeStart: {
      type: [Number, String, undefined],
      default: 0,
    },
    xRangeEnd: {
      type: [Number, String, undefined],
      default: 1000,
    },
    yRangeStart: {
      type: [Number, String, undefined],
      default: 0,
    },
    yRangeEnd: {
      type: [Number, String, undefined],
      default: 1000,
    },
    zRangeStart: {
      type: [Number, String, undefined],
      default: 0,
    },
    zRangeEnd: {
      type: [Number, String, undefined],
      default: 1000,
    },
    isReplay: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  beforeMount() {
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.widgetPropertyIndex = i;
      }
    }
    this.idx = this.widgetId;

    let displayId = this.displayId;
    let displayDtls = this.$store.state.disp.displays[displayId];
    if (displayDtls) {
      this.wellId = displayDtls.wellId;
      this.wellboreId = displayDtls.wellboreId;
      this.logId = displayDtls.logId;
      this.wellboreState = displayDtls.wellboreState;
      this.logType = displayDtls.logType;
      this.table = displayDtls.table;
    }
    let foundRect = this.$store.state.rect.rects[this.widgetPropertyIndex];
    if (foundRect && !Object.keys(foundRect).includes("gridColor")) {
      this.$store.dispatch("rect/swabSurgeRealTimedata", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: "white",
        field: "gridColor",
      });
    }

  },
  methods: {
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
      // e.stopPropagation();
    },
    async load3dGraph() {
      let displayDtls = this.$store.state.disp.displays[this.displayId];
      if (displayDtls) {
        this.wellId = displayDtls.wellId;
        this.wellboreId = displayDtls.wellboreId;
        this.logId = displayDtls.logId;
        this.wellboreState = displayDtls.wellboreState;
        this.logType = displayDtls.logType;
      }

      // let tagName = this.mnemonicTags;
      let graphData = {};
      // let tagName = this.mnemonicTags.map((tag) => tag.toUpperCase());
      let tagName = Object.values(this.selectedTags);
      tagName[0] = this.selectedTags.y;
      tagName[1] = this.selectedTags.x;
      tagName[2] = this.selectedTags.z;
      let result = {
        columns: ["default0", "default1", "default2", "default3"],
        data: [[0, 0, 0, 0]],
      };
      let checkTagName = tagName.filter(function (element) {
        return (
          element !== undefined &&
          element.toLowerCase().indexOf("default") === -1 &&
          element !== ""
        );
      });

      result.columns = checkTagName;
      result.data = this.megaData.map((ele) => Object.values(ele));

      let tracesText = {
        trace1: [],
        trace2: [],
      };

      tagName[0]
        ? (graphData[tagName[0].toUpperCase()] = [])
        : (graphData["DEFAULT0"] = []);
      tagName[1]
        ? (graphData[tagName[1].toUpperCase()] = [])
        : (graphData["DEFAULT1"] = []);
      tagName[2]
        ? (graphData[tagName[2].toUpperCase()] = [])
        : (graphData["DEFAULT2"] = []);
      tagName[3]
        ? (graphData[tagName[3].toUpperCase()] = [])
        : (graphData["DEFAULT3"] = []);

      this.megaData.map((data) => {
        let iter_string = "";

        if (data && data["a"]) {
          // a - label
          iter_string += "MD";
          iter_string += " : ";
          iter_string += parseFloat(data["a"]).toFixed(1);
          iter_string += "<br>";
        }

        if (data && data["b"]) {
          // b - label
          iter_string += "INCL";
          iter_string += " : ";
          iter_string += parseFloat(data["b"]).toFixed(1);
          iter_string += "<br>";
        }
        if (data && data["c"]) {
          // c - label
          iter_string += "AZI";
          iter_string += " : ";
          iter_string += parseFloat(data["c"]).toFixed(1);
          iter_string += "<br>";
        }

        // Z- label
        iter_string +=
          tagName[2].toUpperCase().indexOf("DEFAULT") == -1
            ? tagName[2].toUpperCase()
            : "Z";
        iter_string += " : ";
        iter_string += parseFloat(data["z"]).toFixed(1);
        iter_string += "<br>";

        if (data && data["d"]) {
          // d - label
          iter_string += "VERTICAL SECTION";
          iter_string += " : ";
          iter_string += parseFloat(data["d"]).toFixed(1);
          iter_string += "<br>";
        }

        // X - label
        iter_string +=
          tagName[1].toUpperCase().indexOf("DEFAULT") == -1
            ? tagName[1].toUpperCase()
            : "X";
        iter_string += " : ";
        iter_string += parseFloat(data["x"]).toFixed(1);
        iter_string += "<br>";

        // Y - label
        iter_string +=
          tagName[0].toUpperCase().indexOf("DEFAULT") == -1
            ? tagName[0].toUpperCase()
            : "Y";
        iter_string += " : ";
        iter_string += parseFloat(data["y"]).toFixed(1);
        iter_string += "<br>";

        if (data && data["e"] && !isNaN(data["e"])) {
          // e - label
          iter_string += "DLS";
          iter_string += " : ";
          iter_string += parseFloat(data["e"]).toFixed(1);
          iter_string += "<br>";
        }

        // X - label
        // iter_string +=
        //   tagName[1].toUpperCase().indexOf("DEFAULT") == -1
        //     ? tagName[1].toUpperCase()
        //     : "X";
        // iter_string += " : ";
        // iter_string += parseFloat(data["x"]).toFixed(1);
        // iter_string += "<br>";

        // Z- label
        // iter_string +=
        //   tagName[2].toUpperCase().indexOf("DEFAULT") == -1
        //     ? tagName[2].toUpperCase()
        //     : "Z";
        // iter_string += " : ";
        // iter_string += parseFloat(data["z"]).toFixed(1);
        // iter_string += "<br>";

        // Y - label
        // iter_string +=
        //   tagName[0].toUpperCase().indexOf("DEFAULT") == -1
        //     ? tagName[0].toUpperCase()
        //     : "Y";
        // iter_string += " : ";
        // iter_string += parseFloat(data["y"]).toFixed(1);
        // iter_string += "<br>";

        // if (data && data["a"]) {
        //   // Y - label
        //   iter_string += "MD";
        //   iter_string += " : ";
        //   iter_string += parseFloat(data["a"]).toFixed(1);
        //   iter_string += "<br>";
        // }

        // if (data && data["b"]) {
        //   // Y - label
        //   iter_string += "INCL";
        //   iter_string += " : ";
        //   iter_string += parseFloat(data["b"]).toFixed(1);
        //   iter_string += "<br>";
        // }
        tracesText.trace1.push(iter_string);
      });

      this.megaDataPt.map((data) => {
        let iter_string = "";

        if (data && data["a"]) {
          // a - label
          iter_string += "MD";
          iter_string += " : ";
          iter_string += parseFloat(data["a"]).toFixed(1);
          iter_string += "<br>";
        }
        if (data && data["b"]) {
          // b - label
          iter_string += "INCL";
          iter_string += " : ";
          iter_string += parseFloat(data["b"]).toFixed(1);
          iter_string += "<br>";
        }
        if (data && data["c"]) {
          // c - label
          iter_string += "AZI";
          iter_string += " : ";
          iter_string += parseFloat(data["c"]).toFixed(1);
          iter_string += "<br>";
        }

        // Z- label
        iter_string +=
          tagName[2].toUpperCase().indexOf("DEFAULT") == -1
            ? tagName[2].toUpperCase()
            : "Z";
        iter_string += " : ";
        iter_string += parseFloat(data["z"]).toFixed(1);
        iter_string += "<br>";

        if (data && data["d"]) {
          // d - label
          iter_string += "VERTICAL SECTION";
          iter_string += " : ";
          iter_string += parseFloat(data["d"]).toFixed(1);
          iter_string += "<br>";
        }

        // X - label
        iter_string +=
          tagName[1].toUpperCase().indexOf("DEFAULT") == -1
            ? tagName[1].toUpperCase()
            : "X";
        iter_string += " : ";
        iter_string += parseFloat(data["x"]).toFixed(1);
        iter_string += "<br>";

        // Y - label
        iter_string +=
          tagName[0].toUpperCase().indexOf("DEFAULT") == -1
            ? tagName[0].toUpperCase()
            : "Y";
        iter_string += " : ";
        iter_string += parseFloat(data["y"]).toFixed(1);
        iter_string += "<br>";

        if (data && data["e"] && !isNaN(data["e"])) {
          // e - label
          iter_string += "DLS";
          iter_string += " : ";
          iter_string += parseFloat(data["e"]).toFixed(1);
          iter_string += "<br>";
        }

        // X - label
        // iter_string +=
        //   tagName[1].toUpperCase().indexOf("DEFAULT") == -1
        //     ? tagName[1].toUpperCase()
        //     : "X";
        // iter_string += " : ";
        // iter_string += parseFloat(data["x"]).toFixed(1);
        // iter_string += "<br>";

        // // Z- label
        // iter_string +=
        //   tagName[2].toUpperCase().indexOf("DEFAULT") == -1
        //     ? tagName[2].toUpperCase()
        //     : "Z";
        // iter_string += " : ";
        // iter_string += parseFloat(data["z"]).toFixed(1);
        // iter_string += "<br>";

        // Y - label
        // iter_string +=
        //   tagName[0].toUpperCase().indexOf("DEFAULT") == -1
        //     ? tagName[0].toUpperCase()
        //     : "Y";
        // iter_string += " : ";
        // iter_string += parseFloat(data["y"]).toFixed(1);
        // iter_string += "<br>";

        // if (data && data["a"]) {
        //   // Y - label
        //   iter_string += "MD";
        //   iter_string += " : ";
        //   iter_string += parseFloat(data["a"]).toFixed(1);
        //   iter_string += "<br>";
        // }

        // if (data && data["b"]) {
        //   // Y - label
        //   iter_string += "INCL";
        //   iter_string += " : ";
        //   iter_string += parseFloat(data["b"]).toFixed(1);
        //   iter_string += "<br>";
        // }
        tracesText.trace2.push(iter_string);
      });

      this.tracesText = tracesText;
      this.traces = { trace1: graphData };
      for (let i in graphData) {
        if (graphData[i].length == 0) {
          graphData[i].push(0);
        }
      }
      this.plotly({ trace1: graphData });
    },
    plotly(traces) {
      let widgetWidth = this.widgetWidth;
      let widgetHeight = this.widgetHeight;
      let gridColor = this.gridColor;
      let backgroundColor = this.backgroundColor;
      let tracesText = this.tracesText;
      let memonics = this.mnemonicTags.map((tags) => tags.toUpperCase());

      function unpack(rows, key) {
        if (rows.length == 0) {
          return [0];
        } else {
          return rows.map(function (row) {
            return row[key];
          });
        }
      }

      let count = 1;

      let defrentiator = "ROPA";

      var trace1 = {
        y: unpack(this.megaData, "x"),
        z: unpack(this.megaData, "z"),
        x: unpack(this.megaData, "y"),
        mode: "markers",
        showlegend: true,
        marker: {
          size: 2,
          symbol: "point",
          opacity: 0.8,
          color: "red",
        },
        name: "Actual",
        type: "scatter3d",
        text: tracesText.trace1,
        hoverinfo: "text",
      };

      var trace2 = {
        y: unpack(this.megaDataPt, "x"),
        z: unpack(this.megaDataPt, "z"),
        x: unpack(this.megaDataPt, "y"),
        mode: "markers",
        showlegend: true,

        marker: {
          size: 2,
          symbol: "circle",
          opacity: 0.8,

          color: "#00d15a",
        },
        name: "Plan",
        type: "scatter3d",
        text: tracesText.trace2,
        hoverinfo: "text",
      };

      count++;

      var data = [trace2, trace1];
      let themeBg = getComputedStyle(document.documentElement).getPropertyValue(
        "--navBar2Bg"
      );
      let plotlyThemeBg = this.darkDark !== "white" ? themeBg : themeBg;
      console.log("```````````````", themeBg);
      var layout = {
        showlegend: true,
        legend: {
          x: 0,
          y: 1,
          itemsizing: "constant",

          // traceorder: 'normal',
          font: {
            family: "sans-serif",
            size: 12,
            color: this.darkDark !== "white" ? "white" : "black",
          },
          bgcolor: plotlyThemeBg,
          bordercolor: "#FFFFFF",
          borderwidth: 0,
        },
        paper_bgcolor: plotlyThemeBg,
        // plot_bgcolor: "rgba(0,0,0,0)",
        width: widgetWidth,
        height: widgetHeight,
        scene: {
          showlegend: true,
          camera:
            // {
            //   center: { x: 0.1, y: 0, z: -0.15 },
            //   eye: { x: 1.5, y: 1.5, z: 1.5 },
            //   up: { x: 0, y: 0.5, z: 1 },
            // }{
            {
              up: {
                x: 0,
                y: 0,
                z: 1,
              },
              center: {
                x: 0.1,
                y: 0,
                z: -0.15,
              },
              eye: {
                x: -0.47796435029893725,
                y: 2.4763219641558454,
                z: 0.5328519163207099,
              },
              projection: {
                type: "perspective",
              },
            },
          aspectratio: { x: 1.1, y: 1.1, z: 1.1 },
          xaxis: {
            title:
              this.selectedTags.y.toUpperCase() +
              " " +
              (this.unitData[this.selectedTags.y]
                ? this.unitData[this.selectedTags.y]
                : ""),
            titlefont: {
              size: 10,
            },
            color: this.darkDark !== "white" ? "white" : "black",
            tickfont: {
              color: this.darkDark !== "white" ? "white" : "black",
              size: 10,
            },
            backgroundfixed: true,
            backgroundcolor: backgroundColor,
            autorange: "reversed",
            gridcolor: gridColor,
            gridwidth: 2,
            showbackground: true,
            zerolinecolor: "rgb(255, 255, 255)",
            fixedrange: true,
          },
          yaxis: {
            title:
              this.selectedTags.x.toUpperCase() +
              " " +
              (this.unitData[this.selectedTags.x]
                ? this.unitData[this.selectedTags.x]
                : ""),
            titlefont: {
              size: 10,
            },
            color: this.darkDark !== "white" ? "white" : "black",
            tickfont: {
              color: this.darkDark !== "white" ? "white" : "black",
              size: 10,
            },
            backgroundcolor: backgroundColor,
            gridcolor: gridColor,
            gridwidth: 2,
            autorange: "reversed",
            showbackground: true,
            zerolinecolor: "rgb(255, 255, 255)",
            fixedrange: true,
          },
          zaxis: {
            title:
              this.selectedTags.z.toUpperCase() +
              " " +
              (this.unitData[this.selectedTags.z]
                ? this.unitData[this.selectedTags.z]
                : ""),
            titlefont: {
              size: 10,
            },
            color: this.darkDark !== "white" ? "white" : "black",
            tickfont: {
              color: this.darkDark !== "white" ? "white" : "black",
              size: 10,
            },
            autorange: "reversed",
            backgroundcolor: backgroundColor,
            gridcolor: gridColor,
            gridwidth: 2,
            showbackground: true,
            zerolinecolor: "rgb(255, 255, 255)",
            fixedrange: true,
          },
        },
        margin: {
          l: 0,
          r: 0,
          b: 0,
          t: 0,
        },
      };

      if (this.xAutoScale) {
        delete layout.scene.yaxis.autorange;
        layout.scene.yaxis.range = [this.xRangeStart, this.xRangeEnd];
      }

      if (this.yAutoScale) {
        delete layout.scene.zaxis.autorange;

        layout.scene.zaxis.range = [this.yRangeEnd, this.yRangeStart];
      }

      if (this.zAutoScale) {
        delete layout.scene.xaxis.autorange;
        layout.scene.xaxis.range = [this.zRangeEnd, this.zRangeStart];
      }
      this.my3d_plot = newPlot(
        "apollo_3d_graph" + this.widgetId,
        data,
        layout,
        {
          displayModeBar: false,
          fixedrange: true,
          showTips: false,
        }
      );
      let myPlotly = document.getElementById("apollo_3d_graph" + this.widgetId);
      myPlotly.on("plotly_legendclick", function (event) {
        if (event.curveNumber === 0) {
          if (data[1].visible) {
            data[1]["visible"] = true;
            // this.$toast.error('To plot the graph one trace is mandatory',{"duration":2000,'position':'top'})
          }
        } else {
          if (data[0].visible) {
            data[0]["visible"] = true;
            // this.$toast.error('To plot the graph one trace is mandatory',{"duration":2000,'position':'top'})
          }
        }
      });
      // });
    },
    async getSingleTagData(tagName) {
      let result = null;
      let dconfig = {};
      tagName = tagName.filter(function (element) {
        return (
          element !== undefined &&
          element.toLowerCase().indexOf("default") === -1 &&
          element !== ""
        );
      });
      dconfig.field = tagName;
      dconfig.logId = this.logId;
      dconfig.logName = getLogname(this.logType);

      dconfig.table = this.table || "source_depth_data";
      if (this.logType === "time") {
        dconfig.limit = 14400;
        // dconfig.limit = 20;
        dconfig.from = 0;
        dconfig.sp = "e";
      }
      let query = getSingleTagData(dconfig);
      result = await executeQuery(query);
      result = await result.data;

      return result;
    },
    async get3DGraphData(dtls) {
      try {
        const response = await apiService.WellServices.getPlanActualData({
          well_id: dtls.wellId,
          wellbore_name: dtls.wellboreId,
        });
        if (this.isReplay) {
          this.graphData.rows=[];
         this.graphData.rowsPlan=response.data.rowsPlan;
         this.graphData.columns=response.data.columns;
         this.graphData.columnsPlan=response.data.columnsPlan;
         let index=0;
         try {
            this.unitData = {};
            response.data.columnsPlan.map((data) => {
              return (this.unitData[data.field] = data.label.split(" ")[1]
                ? `(${data.label.split(" ")[1]})`
                : "");
            });

            this.unitData;
          } catch (error) {}
        if(this.replayTimeIntvPlanVsActual){
          clearInterval(this.replayTimeIntvPlanVsActual);
          this.replayTimeIntvPlanVsActual=null;
        }
        this.replayTimeIntvPlanVsActual = setInterval(async () => {
          if (index < response.data.rows.length) {
            this.graphData.rows.push(response.data.rows[index]);
            index++;
            await this.set3DGraphData();
            this.load3dGraph();
          } else {
            clearInterval(this.replayTimeIntvPlanVsActual);  
            this.replayTimeIntvPlanVsActual=null;
          }
        }, 1000);
        } else {
          this.graphData = response.data;
          try {
            this.unitData = {};
            response.data.columnsPlan.map((data) => {
              return (this.unitData[data.field] = data.label.split(" ")[1]
                ? `(${data.label.split(" ")[1]})`
                : "");
            });

            this.unitData;
          } catch (error) {}
        }
        await this.set3DGraphData();
      } catch (error) {
        console.error("There is no trajectory data in db : ", error.message);
      }
    },
    set3DGraphData() {
      this.megaData = this.graphData.rows.map((ele) => {
        return {
          y: ele[this.selectedTags.y],
          x: ele[this.selectedTags.x],
          z: ele[this.selectedTags.z],
          a: ele[this.selectedTags.a],
          b: ele[this.selectedTags.b],
          c: ele[this.selectedTags.c],
          d: ele[this.selectedTags.d],
          e: ele["dls"],
        };
      });

      this.megaDataPt = this.graphData.rowsPlan.map((ele) => {
        return {
          y: ele[this.selectedTags.y],
          x: ele[this.selectedTags.x],
          z: ele[this.selectedTags.z],
          a: ele[this.selectedTags.a],
          b: ele[this.selectedTags.b],
          c: ele[this.selectedTags.c],
          d: ele[this.selectedTags.d],
          e: ele[this.selectedTags.e],
        };
      });
      const allTags = [
        ...this.graphData.columns,
        ...this.graphData.columnsPlan,
      ];
      const tempTags = allTags.map((ele) => ele.field);
      const tags = [...new Set(tempTags)];
      // const tags = this.graphData.columns.map((ele) => ele.field);
      if(!this.isReplay){
          this.$store.dispatch("disp/setTrejectoryTags", {
          display: this.displayId,
          tags,
        });
      }

    },
    dark() {
      this.$emit("dark");
    },
    light() {
      this.$emit("light");
    },
  },
  computed: {
    darkDark() {
      // if (this.$store.state.rect.darkmode !== 'white') {
      //   this.$store.dispatch("rect/changeBackgroundColor", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: this.backgroundColor || "var(--navBar2Bg)",
      //   });
      //   this.$store.dispatch("rect/changeColor", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: this.gridcolor || "white",
      //   });
      //   // valueColor
      // } else {
      //   this.$store.dispatch("rect/changeBackgroundColor", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: this.backgroundColor || "var(--navBar2Bg)",
      //   });
      //   this.$store.dispatch("rect/changeColor", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: this.gridcolor || "black",
      //   });
      // }
      return this.$store.state.rect.darkmode;
    },
  },
  watch: {
    darkDark() {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 300);
    },
    selectedTags: {
      handler(val) {
        this.set3DGraphData();
        this.load3dGraph();
      },
      deep: true,
    },
    widgetHeight: async function (newVal, oldVal) {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 500);
    },
    widgetWidth: async function (newVal, oldVal) {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 500);
    },
    gridColor: async function (newVal, oldVal) {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 500);
    },
    backgroundColor: async function (newVal, oldVal) {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 500);
    },
    xAutoScale: async function (newVal, oldVal) {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 300);
    },
    xRangeStart: async function (newVal, oldVal) {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 300);
    },
    xRangeEnd: async function (newVal, oldVal) {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 300);
    },
    yAutoScale: async function (newVal, oldVal) {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 300);
    },

    yRangeStart: async function (newVal, oldVal) {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 300);
    },
    yRangeEnd: async function (newVal, oldVal) {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 300);
    },
    zAutoScale: async function (newVal, oldVal) {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 300);
    },
    zRangeStart: async function (newVal, oldVal) {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 300);
    },
    zRangeEnd: async function (newVal, oldVal) {
      clearTimeout(this.widgetWaitTimer);
      this.widgetWaitTimer = setTimeout(() => this.plotly(this.traces), 300);
    },
  },
  async mounted() {
    let dtls = this.$store.state.disp.displays[this.displayId];
    if (dtls) {
      this.wellId = dtls.wellId;
      this.wellboreId = dtls.wellboreId;
      this.logId = dtls.logId;
      this.wellboreState = dtls.wellboreState;
      this.logType = dtls.logType;
      this.table = dtls.table;
      await this.get3DGraphData(dtls);
    }

    this.$store.subscribe(async (mutation, state) => {
      if (mutation.type == "rect/PLOTLY_MNEMONIC_CHANGE") {
        let rects = this.$store.state.rect.rects;

        if (typeof rects != "undefined" && rects.length > 0) {
          for (let i = 0; i < rects.length; i++) {
            if (
              rects[i].widgetId == this.widgetId &&
              rects[i].displayId == this.displayId
            ) {
              this.selectedTags = {
                z: this.$store.state.rect.rects[i].mnemonicTag3,
                x: this.$store.state.rect.rects[i].mnemonicTag1,
                y: this.$store.state.rect.rects[i].mnemonicTag2,
              };
            }
          }
        }
      }

      if (mutation.type == "data/setSingleValue") {
        let display = this.$store.state.disp.displays[this.displayId];

        let valueAt = "end";
        let name =
          this.title +
          "-" +
          this.displayId +
          "-" +
          display.logId +
          "-" +
          valueAt;

        let plName =
          mutation.payload.title +
          "-" +
          mutation.payload.display +
          "-" +
          mutation.payload.logId +
          "-" +
          mutation.payload.valueAt;

        if (name == plName) {
          this.niddleAngle = mutation.payload.value;
        }
      }
      if (mutation.type == "disp/setDisplay") {
        if (this.displayId == mutation.payload.display) {
          this.wellId = mutation.payload.wellId;
          this.wellboreId = mutation.payload.wellboreId;
          this.logId = mutation.payload.logId;
          this.wellboreState = mutation.payload.wellboreState;
          this.logType = mutation.payload.logType;

          this.table = mutation.payload.table;
          // let displayDtls = this.$store.state.disp.displays[this.displayId];
          if (this.wellboreState == "active") {
            // this.widgetTitle = this.title;
            this.defaultTitle = this.title;
          } else {
            this.defaultTitle = this.title;
          }
          await this.get3DGraphData(mutation.payload);
          this.load3dGraph();
        }
      }
      if (mutation.type == "data/displayChanged") {
        let valueAt = "end";

        let name =
          this.title + "-" + this.displayId + "-" + this.logId + "-" + valueAt;
      }
    });

    this.load3dGraph();
    document.querySelector(".main-svg").style.transition = "var(--transition)";
  },
  beforeUnmount(){
    if(this.isReplay && this.replayTimeIntvPlanVsActual){
      clearInterval(this.replayTimeIntvPlanVsActual);
    }
  }
};
</script>
